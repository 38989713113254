<template>
	<div class="forward-log-body">
		<div class="search">
			<Row :gutter="16">
				<Col><Button @click="deleteData()">清除所有</Button></Col>
				<Col><Button @click="$router.go(-1)">返回</Button></Col>
			</Row>
		</div>
		<div class="table">
			<Table :columns="columns" :data="data" :loading="loading">
				<template slot="status" slot-scope="{row}">
					<Tag :color="row.status == 1 ? 'success' : 'error'">{{ row.status == 1 ? '成功' : '失败' }}</Tag>
				</template>
			</Table>
			<div class="ser-page right">
				<Page v-model="search.page" :total="search.total" :page-size="search.pageSize"
					:page-size-opts="[10,15,30,50]" show-total show-sizer transfer @on-change="pageChange"
					@on-page-size-change="pageSizeChange"></Page>
			</div>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		name: 'DeviceForwardLog',
		data() {
			return {
				search: {
					page: 1,
					pageSize: 10,
					total: 0
				},
				loading: false,
				columns: [
					{ title: '设备id', minWidth: 120, width: 160, key: 'device_id' },
					{ title: '状态', width: 80, slot: 'status' },
					{ title: '创建时间', minWidth: 120, width: 160, key: 'create_date' },
					{ title: '转发内容', minWidth: 240, key: 'sen_ctn', tooltip: true },
				],
				data: []
			}
		},
		created() {
			_this = this;
			if (this.$route.query.device_id) {
				this.search.device_id = this.$route.query.device_id;
				this.init()
			}
		},
		methods: {
			init() {
				this.loading = true;
				this.requestApi('/adm/get_device_forward_log',{ search: this.search }).then((res) => {
					if(res.code == 200){
						_this.loading = false;
						_this.data = res.data.data;
						_this.search.total = res.data.total;
					} else {
						_this.alertErr(res.msg);
					}
				});
			},
			// 切换页码
			pageChange(page) {
				this.search.page = page;
				this.init();
			},
			// 切换每页条数
			pageSizeChange(pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			},
			// 清除所有 转发记录
			deleteData(){
				this.$Modal.confirm({
					title: '确认清除',
					content: '确认清除当前设备的所有转发记录吗？此操作将不可逆！',
					onOk: () => {
						_this.requestApi('/adm/del_device_forward_log', { device_id: this.search.device_id }).then((res) => {
							if(res.code == 200){
								_this.alertSucc('清除成功');
								_this.init();
							} else {
								_this.alertErr(res.msg);
							}
						});
					}
				});
			}
		}
	}
</script>

<style scoped>
	.forward-log-body .search {
		padding: 16px;
		background: #ffffff;
	}
	.forward-log-body .table{
		margin-top: 24px;
		padding: 16px;
		background: #ffffff;
	}
	.right{
		text-align: right;
	}
</style>
